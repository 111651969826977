import React, { FC } from 'react';
import {
  LangsContextProvider,
  ModalsContextProvider,
} from 'contexts';


export const RootContext: FC = ({ children }) => {
  return (
    <LangsContextProvider>
      <ModalsContextProvider>
        {children}
      </ModalsContextProvider>
    </LangsContextProvider>
  );
}