import { TLangsData } from 'types';


type TbuttonsData = {
  contacts: string;
  send: string;
  confirm: string;
};

export const buttonsData: TLangsData<TbuttonsData> = {
  ru: {
    contacts: 'Контакты',
    send: 'Отправить',
    confirm: 'Подтверждаю',
  },
  en: {
    contacts: 'Contacts',
    send: 'Get in touch',
    confirm: 'Agree',
  },
  es: {
    contacts: 'Contáctenos',
    send: 'Enviar',
    confirm: 'Confirmo',
  },
};