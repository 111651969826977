// Packanges
import React from 'react';
import ReactDOM from 'react-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'nprogress/nprogress.css';
// Contexts
import {
  RootContext,
} from 'contexts';
// Components
import { RootModal } from 'components/modals';
import { Cookie } from 'components/ui';
// Configs
import RouterConfig from './routerConfig';
import 'middlewares/axiosMiddleware';
// Support for IE11
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
// Styles
import './assets/styles/main.sass';


ReactDOM.render(
  <RootContext>
    <RouterConfig />
    <RootModal />
    <ToastContainer />
    <Cookie />
  </RootContext>, 
  document.getElementById('root')
);