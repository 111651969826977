import React, {
  FC,
  Suspense,
  lazy,
  useEffect,
  useContext,
} from 'react';
import {
  HashRouter as Router,
  Switch,
  Route,
  Redirect,
  useLocation,
} from 'react-router-dom';
import nprogress from 'nprogress';
// Contexts
import { LangsContext } from 'contexts/LangsContext/LangsContext';
// Types
import { TLangs } from 'types';
// Utils
import { getLangFromLink } from 'utils';
// Pages
const Index = lazy(() => import('pages/index/index'));
const Scoring = lazy(() => import('pages/scoring/scoring'));
const UnloadDataInKB = lazy(() => import('pages/unloadDataInKB/unloadDataInKB'));
const Reviews = lazy(() => import('pages/reviews/reviews'));
const Documents = lazy(() => import('pages/documents/documents'));
const Error = lazy(() => import('pages/error/error'));


const SuspenseFallBack: FC = () => {
  useEffect(() => {
    nprogress.start();
    return () => { nprogress.done() };
  });
  return null;
}

const LocationObserver: FC = () => {
  const { lang, setLanguage } = useContext(LangsContext);
  const location = useLocation();

  useEffect(() => {
    const pathName = location.pathname;
    const langInUrl = getLangFromLink(pathName);
    const isDifferentLang = langInUrl !== lang;

    if (isDifferentLang && langInUrl) {
      setLanguage(langInUrl as TLangs);
    }
  }, [location, lang, setLanguage]);
  return null;
}

const RouterConfig: FC = (props) => {
  const { lang } = useContext(LangsContext);
  const langsRouter = ':lang(ru|en|es)';

  return (
    <Suspense fallback={<SuspenseFallBack />}>
      <Router>
        <LocationObserver />
        <Switch>
          <Redirect from={'/'} to={`/${lang}/`} exact />
          <Route
            exact
            path={`/${langsRouter}/`}
            component={() => <Index />}
          />
          <Route
            exact
            path={`/${langsRouter}/scoring`}
            component={() => <Scoring />}
          />
          <Route
            exact
            path={`/${langsRouter}/upload-data-in-kb`}
            component={() => <UnloadDataInKB />}
          />
          <Route
            exact
            path={`/${langsRouter}/reviews`}
            component={() => <Reviews />}
          />
          <Route
            exact
            path={`/${langsRouter}/documentation/:product`}
            component={() => <Documents />}
          />
          <Route 
            path="*" 
            component={() => <Error />} 
          />
        </Switch>
      </Router>
    </Suspense>
  );
}

export default RouterConfig;