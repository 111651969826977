import React, { FC, useContext, } from 'react';
import { 
  ContactsModal, 
  SuccessModal, 
} from '../';
import { ModalsContext, } from 'contexts';


export const RootModal: FC = () => {
  const {
    modal,
    hideAllModals,
    showModal,
  } = useContext(ModalsContext);

  return (
    <>
      <ContactsModal
        show={modal?.name === 'ContactsModal'}
        hideSelf={hideAllModals}
        showModal={showModal}
      />
      <SuccessModal 
        show={modal?.name === 'SuccessModal'}
        hideSelf={hideAllModals}
      />
    </>
  );
}