import React, { ReactElement } from 'react';
import {
  fieldsData,
  contactsData,
} from 'commonData';
import { DEFAULT_LANG } from 'constants/index';
import {
  patternPhone,
  patternEmail,
} from 'constants/index';
import { TLangsData } from 'types';


export interface TField {
  id: number;
  type: string;
  value: string;
  name: string;
  label: string | ReactElement;
  isRequired: boolean;
  isValid: boolean;
  pattern?: RegExp;
};

type TContactsModalData = {
  title: string;
  fields: Array<TField>;
  items: {
    title: string;
    list: Array<{
      id: number;
      link: string;
      desc: string;
    }>;
  }
};

export const contactsModalData: TLangsData<TContactsModalData> = {
  ru: {
    title: 'Обсудим Ваш проект',
    fields: [
      {
        id: 1,
        type: 'input',
        value: '',
        name: 'name',
        label: fieldsData.ru.introduce,
        isRequired: false,
        isValid: true,
      },
      {
        id: 2,
        type: 'input',
        value: '',
        name: 'phone',
        label: fieldsData.ru.phone + '*',
        pattern: patternPhone,
        isRequired: true,
        isValid: false,
      },
      {
        id: 3,
        type: 'input',
        value: '',
        name: 'email',
        label: fieldsData.ru.email + '*',
        pattern: patternEmail,
        isRequired: true,
        isValid: false,
      },
      {
        id: 4,
        type: 'textarea',
        name: 'message',
        value: '',
        label: <>
          {fieldsData.ru.comments}
          <small>
            - Напишите, что Вас заинтересовало <br />
            - Удобное время контакта <br />
            - Другую важную информацию
          </small>
        </>,
        isRequired: false,
        isValid: true,
      },
    ],
    items: {
      title: 'Другие способы связи',
      list: [
        {
          id: 1,
          link: contactsData.ru.phone,
          desc: 'Телефон московского офиса',
        },
        {
          id: 2,
          link: contactsData.ru.email,
          desc: 'Всегда доступны по почте',
        },
      ]
    }
  },
  en: {
    title: 'Contact us',
    fields: [
      {
        id: 1,
        type: 'input',
        value: '',
        name: 'name',
        label: (fieldsData.en || fieldsData[DEFAULT_LANG]).introduce,
        isRequired: false,
        isValid: true,
      },
      {
        id: 2,
        type: 'input',
        value: '',
        name: 'phone',
        label: (fieldsData.en || fieldsData[DEFAULT_LANG]).phone + '*',
        pattern: patternPhone,
        isRequired: true,
        isValid: false,
      },
      {
        id: 3,
        type: 'input',
        value: '',
        name: 'email',
        label: (fieldsData.en || fieldsData[DEFAULT_LANG]).email + '*',
        pattern: patternEmail,
        isRequired: true,
        isValid: true,
      },
      {
        id: 4,
        type: 'textarea',
        name: 'message',
        value: '',
        label: <>
          {(fieldsData.en || fieldsData[DEFAULT_LANG]).comments}
          <small>
            If you have any questions or would like to schedule a demo, feel free
            to contact us and we'll get back to you right away. - Ask questions <br/>
            - Schedule a demo <br/>
            - Get more information
          </small>
        </>,
        isRequired: false,
        isValid: true,
      },
    ],
    items: {
      title: 'Contact information',
      list: [
        {
          id: 1,
          link: (contactsData.en || fieldsData[DEFAULT_LANG]).phone,
          desc: 'Moscow Office',
        },
        {
          id: 2,
          link: (contactsData.en || fieldsData[DEFAULT_LANG]).email,
          desc: 'We are always available via email',
        },
      ]
    }
  },
  es: {
    title: 'Vamos a discutir su proyecto',
    fields: [
      {
        id: 1,
        type: 'input',
        value: '',
        name: 'name',
        label: (fieldsData.es || fieldsData[DEFAULT_LANG]).introduce,
        isRequired: false,
        isValid: true,
      },
      {
        id: 2,
        type: 'input',
        value: '',
        name: 'phone',
        label: (fieldsData.es || fieldsData[DEFAULT_LANG]).phone + '*',
        pattern: patternPhone,
        isRequired: true,
        isValid: false,
      },
      {
        id: 3,
        type: 'input',
        value: '',
        name: 'email',
        label: (fieldsData.es || fieldsData[DEFAULT_LANG]).email + '*',
        pattern: patternEmail,
        isRequired: true,
        isValid: true,
      },
      {
        id: 4,
        type: 'textarea',
        name: 'message',
        value: '',
        label: <>
          {(fieldsData.es || fieldsData[DEFAULT_LANG]).comments}
          <small>
            - Escribe qué le despierta interés <br />
            - Una hora conveniente para comunicación <br />
            - Otra información importante
          </small>
        </>,
        isRequired: false,
        isValid: true,
      },
    ],
    items: {
      title: 'Otros datos de contacto',
      list: [
        {
          id: 1,
          link: (contactsData.es || fieldsData[DEFAULT_LANG]).phone,
          desc: 'Teléfono de la oficina en Moscú',
        },
        {
          id: 2,
          link: (contactsData.en || fieldsData[DEFAULT_LANG]).email,
          desc: 'Siempre estamos disponibles por el correo electrónico',
        },
      ]
    }
  },
};