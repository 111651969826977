import { TLangsData } from 'types';


type TfieldsData = {
  introduce: string;
  phone: string;
  email: string;
  comments: string;
};

export const fieldsData: TLangsData<TfieldsData> = {
  ru: {
    introduce: 'Представьтесь',
    phone: 'Телефон',
    email: 'Почта',
    comments: 'Комментарии',
  },
  en: {
    introduce: 'Your name',
    phone: 'Your phone number',
    email: 'Email',
    comments: 'Comments',
  },
  es: {
    introduce: 'Presentadme',
    phone: 'Nombre Teléfono',
    email: 'Correo Electrónico',
    comments: 'Comentarios',
  },
};