import React, {FC} from "react";
import {getLink} from "../../../../utils";

interface TBreadcrumbs {
  productName: string;
  pathname: string;
  lang: string;
};

export const Breadcrumbs: FC<TBreadcrumbs> = ({
  productName,
  pathname,
  lang
}) => {
  return (
    <ul className="header__breadcrumbs_items">
      <li className="header__breadcrumbs_item">
        {pathname === `/${lang}/${productName}` ?
          <span className="header__breadcrumbs_link header__breadcrumbs_link_active">О продукте</span>
          : getLink(
            true,
            productName,
            {
              className: 'header__breadcrumbs_link'
            },
            lang,
            <>О продукте</>,
          )
        }
      </li>
      <li className="header__breadcrumbs_item">
        {pathname.indexOf('/documentation/')+1 ?
          <span className="header__breadcrumbs_link header__breadcrumbs_link_active">Документы</span>
          : getLink(
            true,
            `documentation/${productName}`,
            {
              className: 'header__breadcrumbs_link'
            },
            lang,
            <>Документы</>,
          )
        }
      </li>
    </ul>
  )
}