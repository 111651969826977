import React, { FC } from 'react';


interface TLogo {
  color?: string;
};

export const Logo: FC<TLogo> = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="112"
      height="23"
      fill="none"
      viewBox="0 0 112 23"
    >
      <mask width="19" height="23" x="0" y="0" maskUnits="userSpaceOnUse">
        <path fill={color} d="M0 0h18.587v22.739H0V0z" />
      </mask>
      <path
        fill={color}
        fillRule="evenodd"
        d="M7.469 11.37a9.028 9.028 0 0111.118-8.784A11.319 11.319 0 0011.37 0C5.09 0 0 5.091 0 11.37c0 6.279 5.09 11.369 11.369 11.369 2.741 0 5.255-.97 7.218-2.585A9.028 9.028 0 017.468 11.37zM23.794 1.667v4.388a8.988 8.988 0 011.73 5.315 8.991 8.991 0 01-1.73 5.315v4.402h6.06V6.374h6.166v14.713h6.06V1.666H23.794zM46.84 1.667v4.635h5.608v14.785h6.06V6.302h5.607V1.667H46.84zM68.88 1.667v19.42h6.061v-7.91h6.273v7.91h6.06V1.666h-6.06V8.4H74.94V1.666H68.88zM92.03 1.632v19.455h6.06v-7.69h1.589l4.595 7.69h6.963l-6.025-9.728 6.025-9.727h-6.963l-4.595 7.69H98.09v-7.69h-6.06z"
        clipRule="evenodd"
      />
    </svg>
  );
}

Logo.defaultProps = {
  color: '#1D2C62'
};