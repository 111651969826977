import { TLangsData } from 'types';


type TCookieData = {
  text: string;
};

export const cookieData: TLangsData<TCookieData> = {
  ru: {
    text: `
      Продолжая просмотр сайта sptnk.co, я соглашаюсь с использованием 
      файлов cookie владельцем сайта в соответствии с «Политикой в отношении файлов 
      cookie», в том числе на передачу данных, указанных в Политике, третьим лицам 
      (статистическим службам сети Интернет)
    `,
  },
  en: {
    text: `
      By continuing to browse the site sptnk.co, I agree to the use of 
      cookies by the site owner in accordance with the " cookie Policy 
      cookies", including the transfer of data specified in the Policy to third parties 
      (for statistical services on the Internet)
    `,
  },
  es: {
    text: `
      Continuando navegando por el sitio sptnk.co. estoy de acuerdo con el uso de 
      cookies por el propietario del sitio de acuerdo con la " política de cookies 
      cookies", incluida la transferencia de datos especificados en la Política a terceros 
      (servicios estadísticos de Internet)
    `,
  },
};