import React, { ReactElement } from 'react';
import { TLangsData } from 'types';


type TcontactsData = {
  phone: string;
  email: string;
  address: string | ReactElement;
};

export const contactsData: TLangsData<TcontactsData> = {
  ru: {
    phone: '+7 495 006 21 57',
    email: 's@sptnk.co',
    address: 'Россия, город Мурманск, улица Октябрьская, дом 2а, офис 2',
  },
  en: {
    phone: '+7 495 006 21 57',
    email: 's@sptnk.co',
    address: <>
      Flicker, LLC, <br />
      Republic of Armenia, Yerevan, st. T. Petrosyan, building 40/2, room No. 74 <br />
      Reg number 53188403
    </>,
  },
  es: {
    phone: '+7 495 006 21 57',
    email: 's@sptnk.co',
    address: <>
      Oktyabrskaya, <br />
      casa 2a, oficina 2, <br /> Murmansk, Rusia
    </>,
  },
};