import React, { FC } from 'react';
import classNames from 'classnames';
import './Button.sass';


interface TypeButton {
  classes?: string;
  theme?: string;
  size?: string;
  disabled?: boolean;
  onClick?: () => void | Function | Promise<void>;
}

export const Button: FC<TypeButton> = ({
  children,
  classes,
  theme,
  size,
  disabled,
  onClick,
}) => {
  const btnClasses = classNames({
    btn: true,
    [`${classes}`]: true,
    [`btn_theme_${theme}`]: true,
    [`btn_size_${size}`]: true,
  });

  return (
    <button 
      className={btnClasses}
      onClick={onClick}
      disabled={disabled}
    >
      <span>{children}</span>
    </button>
  );
}

Button.defaultProps = {
  classes: '',
  theme: 'red',
  size: 'default',
  disabled: false,
};