// Packanges
import React, {
  FC,
  useContext,
  useState,
} from 'react';
// Components
import { Button } from 'components/ui';
// Contexts
import { LangsContext } from 'contexts/LangsContext/LangsContext';
// Data constants
import { DEFAULT_LANG } from 'constants/index';
import { buttonsData } from 'commonData';
import { cookieData } from './Cookie.data';
import './Cookie.sass';


export const Cookie: FC = () => {
  const { lang } = useContext(LangsContext);
  const hasConfirmCookie = localStorage.getItem('confirmedCookie');
  const [confirmCookie, setConfirmCookie] = useState<boolean>(!!hasConfirmCookie);

  function handleConfirmCookie(): void {
    localStorage.setItem('confirmedCookie', 'true')
    setConfirmCookie(true);
  }

  return (
    !confirmCookie ? (
      <div className="cookie">
        <div className="container">
          <p>{(cookieData[lang] || cookieData[DEFAULT_LANG]).text}</p>
          <Button 
            theme="white"
            onClick={handleConfirmCookie}
          >
            {(buttonsData[lang] || buttonsData[DEFAULT_LANG]).confirm}
          </Button>
        </div>
      </div>
    ) : null
  );
}