// Packages
import React, { FC, useContext } from 'react';
// Contexts
import {
  LangsContext,
} from 'contexts';
// Components
import {
  Langs,
} from 'components/ui';
// Utils
import {
  getLink,
} from 'utils';
// Images
import Logo from 'assets/img/icons/logo_sputnik.svg';
import IconYoutube from 'assets/img/icons/youtube.svg';
import IconMedium from 'assets/img/icons/medium.svg';
// Data constants
import { DEFAULT_LANG } from 'constants/index';
import {
  footerData,
} from './Footer.data';
import {
  contactsData as staticContactsData,
} from 'commonData';
import './Footer.sass';


export const Footer: FC = () => {
  const { lang } = useContext(LangsContext);

  const footer = footerData[lang] || footerData[DEFAULT_LANG];
  const contactsData = staticContactsData[lang] || staticContactsData[DEFAULT_LANG];

  function renderNavRows() {
    return Object.keys(footer.navRows).map((key, ind) => {
      const row = footer.navRows[ind];

      return (
        <div
          className="footer__nav"
          key={key}
        >
          <h4>{row.title}</h4>
          <ul className="footer__nav_items">
            {Object.keys(row.items).map(itemKey => {
              const navItem = row.items[itemKey];

              if (!navItem) return false;

              return (
                <li
                  className="footer__nav_item"
                  key={itemKey}
                >
                  {getLink(
                    navItem.isRouterLink,
                    navItem.link,
                    {
                      className: 'footer__social_link',
                      target: navItem.target,
                      rel: navItem.rel,
                    },
                    lang,
                    <>{navItem[lang].name}</>,
                  )}
                </li>
              );
            })}
          </ul>
        </div>
      );
    });
  }

  return (
    <footer className="footer">
      <div className="container">
        <div className="footer__flex">
          <div className="footer__about">
            <img src={Logo} alt="logo" className="footer__logo" />
            <p>{footer.about}</p>
            <Langs />
          </div>

          {renderNavRows()}

          <div className="footer__contacts">
            <h4>{footer.officeTitle}</h4>
            <a
              href={`tel:${contactsData.phone}`}
              className="footer__phone"
            >
              {contactsData.phone}
            </a>
            <p>{contactsData.address}</p>
          </div>
        </div>
        <div className="footer__flex">
          <div className="footer__langs">
            <Langs />
            <p>© 2013–{new Date().getFullYear()}, «sptnk»</p>
          </div>
          <div className="footer__socialWrp">
            <ul className="footer__social">
              <li className="footer__social_item">
                <a
                  href="https://www.youtube.com/channel/UCUxcFnayn1uSLn7lTlsmePA"
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                  className="footer__social_link"
                >
                  <img src={IconYoutube} alt="icon" />
                </a>
              </li>
              <li className="footer__social_item">
                <a
                  href="https://medium.com/@sptnk"
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                  className="footer__social_link"
                >
                  <img src={IconMedium} alt="icon" />
                </a>
              </li>
            </ul>
            {/* <div className="footer__partner">
              {footer.allianceWith} <a href="https://wndrbase.com/" target="_blank" rel="noopener noreferrer nofollow">Wonderbase</a>
            </div> */}
          </div>
        </div>
      </div>
    </footer>
  );
}