import React, { FC, useState, useEffect } from 'react'; 
import './TypingAnimate.sass';


interface TTypingAnimate {
  stringArray: Array<string>;
  duration?: number;
  delay?: number;
  cursorDuration?: number;
}

export const TypingAnimate: FC<TTypingAnimate> = ({
  stringArray,
  duration = 1000,
  delay = 4000,
  cursorDuration = .8,
}) => {
  const [typingValue, setTypingValue] = useState<string>('');

  useEffect(() => {
    let value = '';
    let stringInd = 0;
    let charInd = 0;
    let canDelete = false;
    let timeout: ReturnType<typeof setTimeout>;

    function addChar(currentString: string): void {
      value += currentString[charInd];
      setTypingValue(value);
      charInd++;
    }

    function deleteChar(): void {
      canDelete = true;

      if (charInd === 0) {
        // Update currentString
        const nextStringIndx = stringInd + 1;

        canDelete = false;
        stringInd = nextStringIndx < stringArray.length ? nextStringIndx : 0;
      } else {
        value = value.slice(0, -1);
        setTypingValue(value);
        charInd--;
      }
    }
    
    function typingAnimation() {
      let currentString = stringArray[stringInd];
  
      if (charInd < currentString.length && !canDelete) {
        addChar(currentString);
      } else {
        deleteChar();
      }

      // Need reinit interval for duration and delay animation
      clearInterval(interval);

      if (charInd === currentString.length) {
        timeout = setTimeout(() => {
          interval = setInterval(typingAnimation, duration / currentString.length);
        }, delay);
      } else {
        interval = setInterval(typingAnimation, duration / currentString.length);
      }
    }
    let interval = setInterval(typingAnimation, duration / stringArray[stringInd].length);

    return () => {
      clearTimeout(timeout);
      clearInterval(interval);
    };
  }, 
  [stringArray, duration, delay]);

  return (
    <>
      {typingValue}
      <i 
        className="typingAnimate__cursor"
        style={{
          animationDuration: `${cursorDuration}s`,
        }}
      >
        &zwnj;
      </i>
    </>
  );
}