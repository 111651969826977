import { AxiosError } from 'axios';
import { axiosClient } from 'clients';
import nprogress from 'nprogress';
import { toast } from 'react-toastify';

function _reject(error: AxiosError): void {
  nprogress.done();
  toast.error(error.toString(), {
    autoClose: false,
  });
}

axiosClient.interceptors.request.use(
  config => config,
  error => {
    _reject(error);
    return Promise.reject(error);
  }
);

axiosClient.interceptors.response.use(
  response => {
    if (response.data?.data) {
      response.data = {
        ...response.data?.data,
      };
    }
    nprogress.done();
    return response;
  },
  error => {
    _reject(error);
    return Promise.reject(error);
  }
);
