export interface TNavItem {
  ru: { name: string; desc?: string; },
  en: { name: string; desc?: string; },
  es: { name: string; desc?: string; },
  isRouterLink: boolean;
  link: string;
  target: string;
  id?: number;
  label?: string;
  rel?: string;
  dropDown?: { [key: string]: TNavItem; };
};

export const commonNav = {
  products: {
    id: 1,
    ru: { name: 'Продукты' },
    en: { name: 'Products' },
    es: { name: 'Productos' },
    isRouterLink: true,
    link: '',
    target: '_self',
  },
  about: {
    ru: { name: 'О компании' },
    en: { name: 'Abous us' },
    es: { name: 'Nosotros' },
    isRouterLink: false,
    link: 'company.html',
    target: '_self',
  },
  reviews: {
    ru: { name: 'Отзывы' },
    en: { name: 'Reviews' },
    es: { name: 'Observaciones' },
    isRouterLink: true,
    link: 'reviews',
    target: '_self',
  },
  blog: {
    ru: { name: 'Блог' },
    en: { name: 'Blog' },
    es: { name: 'Blog' },
    isRouterLink: false,
    link: 'https://medium.com/@sptnk',
    target: '_blank',
    rel: 'nofollow',
  },
  vacantion: {
    ru: { name: 'Вакансии' },
    en: { name: '' },
    es: { name: '' },
    isRouterLink: false,
    link: 'vacancy.html',
    target: '_self',
  },
};

export const productsNav = {
  creditConveyer: {
    ru: {
      name: 'Credit Conveyer',
      desc: 'Ускорит и автоматизирует процесс выдачи кредитов и займов',
    },
    en: {
      name: 'Lending Software',
      desc: 'Sortware for Micro & SME lending, POS lending, Secured lending.',
    },
    es: {
      name: 'Credit Conveyer',
      desc: 'La automatización de todos los procesos de negocio de las organizaciones de microfinanza',
    },
    isRouterLink: false,
    link: 'credit.html',
    target: '_self',
  },
  depositConveyer: {
    ru: {
      name: 'Deposit Conveyer',
      desc: 'Решение для кредитного кооператива',
    },
    en: {
      name: 'Investor Software',
      desc: 'Software for deposit and investments management.',
    },
    es: {
      name: 'Deposit Conveyer',
      desc: 'Solución para cooperativa de crédito',
    },
    isRouterLink: false,
    link: 'deposit.html',
    target: '_self',
  },
  dataConnector: {
    ru: {
      name: 'Data Connector',
      desc: 'Получение данных о ФЛ и ЮЛ из разных источников',
    },
    en: {
      name: 'Data Connector',
      desc: 'Easily integrate with many third-party data providers.',
    },
    es: {
      name: 'Data Connector',
      desc: 'Recibo de datos de personas físicas y jurídicas de fuentes diferentes',
    },
    isRouterLink: false,
    link: 'data.html',
    target: '_self',
  },
  p2p: {
    ru: {
      name: 'P2P',
      desc: 'Готовое решение для запуска P2P и P2B площадки кредитования',
    },
    en: {
      name: 'P2P Lending Platform',
      desc: 'Ready-to-use P2P lending platform for consumer or business.',
    },
    es: {
      name: 'P2P Core',
      desc: 'Desarrollo de sitios de crédito P2P',
    },
    isRouterLink: false,
    link: 'p2p.html',
    target: '_self',
  },
  callCenter: {
    ru: {
      name: 'Call Center',
      desc: 'Телефония для автоматизации работы call-центра',
    },
    en: {
      name: 'Call Center Software',
      desc: 'All-in-one call center software for lending.',
    },
    es: {
      name: 'Call Center',
      desc: 'VoIP para la automatización del trabajo de call-center',
    },
    isRouterLink: false,
    link: 'call-center.html',
    target: '_self',
  },
  scoring: {
    ru: {
      name: 'Скоринг',
      desc: 'Решение для автоматизации оценки физических или юридических лиц',
    },
    en: {
      name: 'Scoring',
      desc: 'Solution for automating the assessment of individuals or legal entities',
    },
    es: {
      name: 'Scoring',
      desc: 'Solución para automatizar la evaluación de individuos o empresas',
    },
    label: 'new',
    isRouterLink: true,
    link: 'scoring',
    target: '_self',
  },
  unloadDataInKB: {
    ru: {
      name: 'Выгрузка данных в КБ',
      desc: 'Программа для конвертации и выгрузки данных в кредитные бюро',
    },
    en: {
      name: 'Uploading data from KB',
      desc: 'Program for converting and uploading data to credit bureaus',
    },
    es: {
      name: 'Descarga de datos de kb',
      desc: 'Programa para convertir y descargar datos a agencias de crédito',
    },
    label: 'new',
    isRouterLink: true,
    link: 'upload-data-in-kb',
    target: '_self',
  },
  development: {
    ru: {
      name: 'Development',
      desc: 'Разработка для fintech-компаний и стартапов',
    },
    en: {
      name: 'Software Development',
      desc: 'Quickly expand your custom product with ready-to-use blocks.',
    },
    es: {
      name: 'Development',
      desc: 'Desarrollo para emresas de fintech y startupes',
    },
    isRouterLink: false,
    link: 'development.html',
    target: '_self',
  },
  // wonderbase: {
  //   ru: {
  //     name: 'Wonderbase',
  //     desc: 'Разработка сайтов и мобильных приложений',
  //   },
  //   en: {
  //     name: 'Wonderbase',
  //     desc: 'Web studio with a focus on creating unique Web & Mobile projects',
  //   },
  //   es: {
  //     name: 'Wonderbase',
  //     desc: 'El desarrollo de sitios y aplicaciones móviles',
  //   },
  //   isRouterLink: false,
  //   link: 'https://wndrbase.com/',
  //   target: '_blank',
  // },
};