import React, { ReactElement } from 'react';
import { TLangsData } from 'types';


type TSuccessModalData = {
  title: string;
  desc: string | ReactElement;
};

export const SuccessModalData: TLangsData<TSuccessModalData> = {
  ru: {
    title: 'Заявка получена',
    desc: <>
      Наш менеджер уже работает над вашей заявкой <br/> 
      и сможет в ближайшее время дать ответ
    </>,
  },
  en: {
    title: 'Thank you!',
    desc: <>
      Message has been successfully sent. <br/>
      We will contact you soon.
    </>,
  },
  es: {
    title: 'La petición es recibida',
    desc: <>
      Nuestro manager está trabajando con su petición <br/>
      y en breve le contestará
    </>,
  },
};