import React, { useState, createContext, FC } from 'react';
const initModal = {
  name: '',
  data: {},
};


export const ModalsContext = createContext({
  modal: initModal,
  showModal: (modalName: string, data?: Object): void => { },
  hideAllModals: (): void => { },
});

export const ModalsContextProvider: FC = ({ children }) => {
  const [modal, setModal] = useState(initModal);

  function showModal(modalName: string, data: Object = {}): void {
    setModal({ name: modalName, data });
  }

  function hideAllModals(): void {
    setModal(initModal);
  }

  return (
    <ModalsContext.Provider value={{ modal, showModal, hideAllModals, }}>
      {children}
    </ModalsContext.Provider>
  );
}