import React, { FC, CSSProperties, } from 'react';
import classNames from 'classnames';
import './Skeleton.sass';


interface TSkeleton {
  type?: 'text' | 'block' | 'circle';
  style?: CSSProperties;
  lines?: number;
  className?: string;
};

export const Skeleton: FC<TSkeleton> = ({
  type,
  style,
  lines,
  className,
}) => {
  const classes = classNames({
    skeleton: true,
    [`skeleton_type_${type}`]: !!type,
    [`${className}`]: !!className,
  });

  switch (type) {
    case 'text':
      return (
        <>
          {new Array(lines).fill(null).map((el, ind) => (
            <span
              className={classes}
              key={ind}
            >
              &zwnj;
            </span>
          ))}
        </>
      );

    case 'block':
    case 'circle':
      return (
        <div 
          className={classes}
          style={style}
        />
      );

    default: return null;
  }
}

Skeleton.defaultProps = {
  type: 'text',
  lines: 1,
};