// Packanges
import React, { FC, useContext, } from 'react';
// Contexts
import { 
  LangsContext,
} from 'contexts';
// Componenets
import {
  Logo,
  Button,
} from 'components/ui';
// Constants data
import { SuccessModalData } from './Success.data';
import classes from './SuccessModal.module.sass';


interface TSuccessModal {
  show?: boolean;
  hideSelf?: () => void | Function;
}


export const SuccessModal: FC<TSuccessModal> = ({
  show,
  hideSelf,
}) => {
  const { lang } = useContext(LangsContext);
  const success = SuccessModalData[lang];

  return (
    <div className={`${classes.success} ${show ? classes.success_open : ''}`}>
      <div className={classes.success__top}>
        <Logo color="white" />
        <button
          className={classes.success__close}
          onClick={hideSelf}
        />
      </div>
      <div className={classes.success__bottom}>
        <h3>{success?.title}</h3>
        <p>{success?.desc}</p>
        <Button 
          theme="white" 
          onClick={hideSelf}
        >
          Ок
        </Button>
      </div>
    </div>
  );
}