import React, { FC, } from 'react';
import './IconCircle.sass';


interface TIconCircle {
  iconLink: string;
  bgColor?: string;
  size?: 'big';
};

export const IconCircle: FC<TIconCircle> = ({
  iconLink,
  bgColor,
  size,
}) => {
  return (
    <span 
      className={`iconCircle ${size ? 'iconCircle_size_'+size : ''}`}
      style={{ backgroundColor: bgColor }}
    >
      {iconLink && <img src={iconLink} alt="icon" />}
    </span>
  );
}

IconCircle.defaultProps = {
  bgColor: '#4445ED',
};