// Packages
import React, { FC, ChangeEvent, } from 'react'; 
import classNames from 'classnames';
// Data constants
import './Input.sass';


interface TInput {
  type?: string;
  name?: string;
  value?: string;
  placeholder?: string;
  id?: string;
  size?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
};

export const Input: FC<TInput> = ({
  type,
  name,
  value,
  placeholder,
  id,
  size,
  onChange,
}) => {
  const inpClasses = classNames({
    input: true,
    [`input_size_${size}`]: true,
  });

  return (
    <input 
      type={type}
      name={name}
      value={value}
      placeholder={placeholder}
      id={id}
      className={inpClasses}
      onChange={onChange}
    />
  );
}

Input.defaultProps = {
  type: 'text',
  name: 'field',
  size: 'default',
};