import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';


export function getLink<Object>(
  isRouterLink: boolean | undefined,
  link: string,
  props: Object,
  lang: string = '',
  children?: ReactElement,
): ReactElement {
  if (isRouterLink) {
    return <Link to={`/${lang}/${link}`} {...props}>{children}</Link>;
  } else {
    const hrefLink = /^http/.test(link) ? link : `https://sptnk.co/${lang}/${link}`;
    return <a href={hrefLink} {...props}>{children}</a>
  }
}