import React, { FC, ChangeEvent } from 'react'; 
import './Textarea.sass';


interface TTextarea {
  name?: string;
  id?: string;
  value?: string;
  onChange?: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  placeholder?: string;
};

export const Textarea: FC<TTextarea> = ({
  name,
  id,
  value,
  onChange,
  placeholder,
  children,
}) => {
  return (
    <textarea 
      name={name}
      id={id}
      value={value}
      placeholder={placeholder}
      className="textarea" 
      onChange={onChange}
    >
      {children}
    </textarea>
  );
}

Textarea.defaultProps = {
  name: 'field',
};