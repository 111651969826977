// Packages
import React, { 
  FC, 
  useState, 
  createContext, 
} from 'react';
// Types
import { TLangs } from 'types';
// Constants data
import { DEFAULT_LANG } from 'constants/index';
// Utils
import { getLangFromLink } from 'utils';


const initLanguage: TLangs = 
  getLangFromLink(window.location.href) ||
  localStorage.getItem('lang') as TLangs || 
  DEFAULT_LANG;
  
export const LangsContext = createContext({
  lang: initLanguage,
  setLanguage: (val: TLangs): void => { },
});

export const LangsContextProvider: FC = ({
  children,
}) => {
  const [lang, setStateLanguage] = useState<TLangs>(initLanguage);

  function setLanguage(val: TLangs): void {
    setStateLanguage(val);
    localStorage.setItem('lang', val);
  }

  return (
    <LangsContext.Provider value={{ lang, setLanguage, }}>
      {children}
    </LangsContext.Provider>
  );
};