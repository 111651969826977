import {
  productsNav,
  commonNav,
  TNavItem,
} from 'commonData';
import { TLangsData } from 'types';


type TFooterData = {
  about: string;
  officeTitle: string;
  allianceWith: string;
  navRows: Array<{
    id: number;
    title: string;
    items: {
      [key: string]: TNavItem
    }
  }>;
};

export const footerData: TLangsData<TFooterData> = {
  ru: {
    about: `
    Программное обеспечение
    и разработка современных
    fintech-решений.
  `,
    officeTitle: 'Центральный офис',
    allianceWith: 'в альянсе с',
    navRows: [
      {
        id: 1, title: 'Продукты', items: {
          p2pCore: productsNav.p2p,
          depositConveyer: productsNav.depositConveyer,
          dataConnector: productsNav.dataConnector,
          callCenter: productsNav.callCenter,
          scoring: productsNav.scoring,
          unloadDataInKB: productsNav.unloadDataInKB,
        },
      },
      // {
      //   id: 2, title: 'Услуги', items: {
      //     wonderbase: productsNav.wonderbase,
      //     development: productsNav.development,
      //   },
      // },
      {
        id: 3, title: 'Компания', items: {
          about: commonNav.about,
          reviews: commonNav.reviews,
          vacantion: commonNav.vacantion,
          blog: commonNav.blog,
        },
      },
    ],
  },
  en: {
    about: `
    Software for P2P lending,
    POS lending, SME lending,
    Micro lending and so on
  `,
    officeTitle: 'Head Office',
    allianceWith: 'In the alliance with',
    navRows: [
      {
        id: 1, title: 'Products', items: {
          p2pCore: productsNav.p2p,
          depositConveyer: productsNav.depositConveyer,
          dataConnector: productsNav.dataConnector,
          callCenter: productsNav.callCenter,
          scoring: productsNav.scoring,
          unloadDataInKB: productsNav.unloadDataInKB,
        },
      },
      // {
      //   id: 2, title: 'Services', items: {
      //     wonderbase: productsNav.wonderbase,
      //     development: productsNav.development,
      //   },
      // },
      {
        id: 3, title: 'About us', items: {
          about: commonNav.about,
          reviews: commonNav.reviews,
          vacantion: commonNav.vacantion,
          blog: commonNav.blog,
        },
      },
    ],
  },
  es: {
    about: `
    Software y el desarrollo
    de decisiones
    de fintech modernas.
  `,
    officeTitle: 'La oficina central',
    allianceWith: 'En la alianza con',
    navRows: [
      {
        id: 1, title: 'Productos', items: {
          p2pCore: productsNav.p2p,
          depositConveyer: productsNav.depositConveyer,
          dataConnector: productsNav.dataConnector,
          callCenter: productsNav.callCenter,
          scoring: productsNav.scoring,
          unloadDataInKB: productsNav.unloadDataInKB,
        },
      },
      // {
      //   id: 2, title: 'Servicios', items: {
      //     wonderbase: productsNav.wonderbase,
      //     development: productsNav.development,
      //   },
      // },
      {
        id: 3, title: 'Nosotros', items: {
          about: commonNav.about,
          reviews: commonNav.reviews,
          vacantion: commonNav.vacantion,
          blog: commonNav.blog,
        },
      },
    ],
  },
}