import React, { FC } from 'react';
import {
  Skeleton,
} from 'components/ui';
import './SectionHeading.sass';


interface TSectionHeading {
  heading?: string;
  subHeading?: string;
  subHeadingWidth?: number;
  showSubHeading?: boolean;
};

export const SectionHeading: FC<TSectionHeading> = ({
  heading,
  subHeading,
  subHeadingWidth,
  showSubHeading,
}) => {
  return (
    <div className="sectionHeading">
      <h2>{heading || <Skeleton type="text" />}</h2>
      
      {showSubHeading && (
        <p
          style={{ maxWidth: subHeadingWidth ? subHeadingWidth : 'auto' }}
        >
          {subHeading || <Skeleton lines={3} />}
        </p>
      )}
    </div>
  );
}

SectionHeading.defaultProps = {
  subHeading: '',
  showSubHeading: true,
};