import {
  productsNav,
  commonNav,
  TNavItem,
} from 'commonData';
import { TLangsData } from 'types';


type THeaderData = {
  nav: {
    [key: string]: TNavItem | null
  }
};

export const headerData: TLangsData<THeaderData> = {
  ru: {
    nav: {
      products: {
        ...commonNav.products,
        dropDown: { ...productsNav },
      },
      about: commonNav.about,
      reviews: commonNav.reviews,
      vacantion: commonNav.vacantion,
      blog: commonNav.blog,
    },
  },
  en: {
    nav: {
      products: {
        ...commonNav.products,
        dropDown: { ...productsNav },
      },
      about: commonNav.about,
      reviews: commonNav.reviews,
      vacantion: null,
      blog: commonNav.blog,
    },
  },
  es: {
    nav: {
      products: {
        ...commonNav.products,
        dropDown: { ...productsNav },
      },
      about: commonNav.about,
      reviews: commonNav.reviews,
      vacantion: null,
      blog: commonNav.blog,
    },
  },
}