import { TLangs } from 'types';
import { patternLangs } from 'constants/index';


export const getLangFromLink = (link: string): TLangs | null => {
  const hasLangInLink: any = link.match(patternLangs);

  if (hasLangInLink) {
    localStorage.setItem('lang', hasLangInLink[1]);
    return hasLangInLink[1];
  }

  return null;
}