import React, {
  FC,
  Component,
  ErrorInfo
} from 'react';


class ErrorBoundaryClass extends Component {
  componentDidCatch(err: { stack?: string }, log: ErrorInfo) {
    console.log(err, log);
  }

  render() {
    return (
      <>{this.props.children}</>
    );
  }
}

export const ErrorBoundary: FC = ({ children }) => (
  <ErrorBoundaryClass>{children}</ErrorBoundaryClass>
);