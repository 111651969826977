// Packages
import React, {
  FC,
  useContext,
  useState,
  useEffect,
} from 'react';
import {
  useHistory,
} from 'react-router-dom';
import classNames from 'classnames';
// Contexts
import { LangsContext } from 'contexts/LangsContext/LangsContext';
// Types
import { TLangs } from 'types';
// Icons
import IconRussia from 'assets/img/icons/Russia.svg';
import IconUSA from 'assets/img/icons/USA.svg';
import IconSpain from 'assets/img/icons/Spain.svg';
// Icons
import './Langs.sass';


type Toptions = {
  [key in TLangs]: {
    icon: string;
    name: string;
  }
};

const options: Toptions = {
  ru: { icon: IconRussia, name: 'Русский' },
  en: { icon: IconUSA, name: 'English' },
  es: { icon: IconSpain, name: 'Español' },
};

interface TLangsComponent {
  type?: string;
}

export const Langs: FC<TLangsComponent> = ({
  type,
}) => {
  const { lang, setLanguage } = useContext(LangsContext);
  const [showLangs, setShowLangs] = useState<boolean>(type !== 'dropdown');
  const history = useHistory();

  useEffect(() => {
    function handleDocumentClick(e: MouseEvent) {
      const target = e.target as HTMLElement;
      const canHideLangs = !target.closest('.langsWrp_type_dropdown');

      canHideLangs && setShowLangs(false);
    }

    if (type === 'dropdown') {  
      document.addEventListener('click', handleDocumentClick);
      return () => document.removeEventListener('click', handleDocumentClick);
    }
  }, [type]);

  function handleChooseLang(lang: TLangs): void {
    setLanguage(lang);

    const newRouterPath: string = history.location.pathname.replace(/(?:\/)(ru|en|es){1}/, `/${lang}`);
    history.push(newRouterPath);
  }

  return (
    <div className={classNames({
      langsWrp: true,
      langsWrp_type_dropdown: type === 'dropdown',
      langsWrp_show_dropdown: showLangs,
    })}>
      {type === 'dropdown' && (
        <button 
          className="langs__btnDropwdown"
          onClick={() => setShowLangs(!showLangs)}
        >
          <img src={options[lang].icon} alt={lang} />
        </button>
      )}

      <ul className="langs">
        {Object.keys(options).map(key => (
          <li
            key={key}
            className={classNames({
              langs__option: true,
              langs__option_current: lang === key,
            })}
          >
            <button
              className="langs__option_btn"
              onClick={() => handleChooseLang(key as TLangs)}
            >
              <img src={options[key as TLangs].icon} alt={key} />
              {type === 'dropdown' && (
                <span>{options[key as TLangs].name}</span>
              )}
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
}